import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a4326e3ab3a0ad6fb86e9d2815010421@o4504479594250240.ingest.sentry.io/4506116017946624",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});